<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import useHttp from '@/comp-functions/useHttp'
import router from "@/router"
import { ref, onMounted } from '@vue/composition-api'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { required } from '@/libs/vee-validate'
// import Swal from "sweetalert2";


export default {
  page: {
    title: "Edit User",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout, PageHeader, Multiselect
  },
   props:{
      user_id: String,
  },
  data() {
    return {
      title: "Edit User",
       select_label: "Select"
    };
  },
  setup(props) {
     const { $get } = useHttp()
     const { $put } = useHttp()
    //  const selectedGoodsType = ref({})
    //  const selectedCcustomer = ref({})
    //  const selectedVendor = ref({})
    //  const selectedPort = ref({})

    let userLogin = JSON.parse(localStorage.getItem('user'))
    const userType = userLogin.usertype

     const LOV = ref({
       userType: [],
        userRole: [],
       customer: [],
       vendor: [],
       ports: [],

     })

       let pUserId = ''
    if(typeof props.user_id !== 'undefined'){
      pUserId = props.user_id
    }

     const getUser = async () => {
       const {data}  = await $get({ url: 'master/user_detail/' + form.value.userId})
       form.value.email = data.email
       form.value.name = data.usr_fullname
       form.value.address = data.usr_address
       form.value.phone = data.usr_phone
       form.value.userType = data.userType
        form.value.customer = data.customer,
        form.value.vendor = data.vendor,
        form.value.ports = data.ports,
        getCustomer()
        getPorts()
        getVendor()
        getUserType()
        getUserRole()
    }

    const getUserType = async () => { 
      const {data} = await $get({ url: 'master/codes/category/UTY'})
      LOV.value.userType = data
    }

    const getCustomer = async () => { 
      const {data} = await $get({ url: 'master/customers'})
      LOV.value.customer = data
    }

    const getVendor = async () => { 
      const {data} = await $get({ url: 'master/vendor'})
      LOV.value.vendor = data
    }

    const getPorts = async () => {
      const {data} = await $get({ url: 'master/port'})
      LOV.value.ports = data
    }

    const getUserRole = async () => {
      const {data} = await $get({ url: 'master/userrole'})
      LOV.value.userRole = data
    }

    const form = ref({
      userId : pUserId,
      email: '',
      name: '',
      address:'',
      phone:'',
      userType: '',
      userRole: '',
      customer: '',
      vendor:'',
      ports:'',
    })

      const validationForm = async () => {

      $put({
        url: 'master/user_edit',
        data: form.value
      })
      router.push({ name: 'appsmd-user-list' })
    }

    onMounted(() => {
      getUser()
    })


    return {
      LOV,
      form,
      validationForm,
      getCustomer,
      getPorts,
      getVendor,
      getUserType,
      getUserRole,
      getUser,
      userType
    }

  },
   methods: {
    customLabel ({ prt_initial, prt_locationname }) {
      return `${prt_initial} – ${prt_locationname}`
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row"> 
              <div class="col-12">
                  <div class="form-group mb-3">
                        <label>Email</label><br />
                        <b-form-input id="" name="" value="" v-model="form.email" readonly></b-form-input>
                      </div>
                  <div class="form-group mb-3">
                        <label>Name</label><br />
                        <b-form-input id="" name="" value="" v-model="form.name"></b-form-input>
                      </div>
                   <div class="form-group mb-3">
                        <label>Address</label><br />
                        <b-form-input id="" name="" value="" v-model="form.address"></b-form-input>
                        </div>
                  <div class="form-group mb-3">
                        <label>Phone</label><br />
                        <b-form-input id="" name="" value="" v-model="form.phone"></b-form-input>
                      </div>
                      <div v-if  = "userType === 'UTYINL' " class="form-group mb-3">
                        <label>User Type</label><br />
                        <multiselect v-model="form.userType" :options="LOV.userType" label="descr_id" track-by="descr_id" :select-label="select_label" ></multiselect>
                      </div>
                      <div v-if  = "userType === 'UTYINL' " class="form-group mb-3 hidden">
                        <label>User Role</label><br />
                        <multiselect v-model="form.userRole" :options="LOV.userRole" label="url_name" track-by="url_name" :select-label="select_label" ></multiselect>
                      </div>

                      <div v-if  = "userType === 'UTYINL' " class="form-group mb-3">
                        <label>Customer</label><br />
                        <multiselect v-model="form.customer" :options="LOV.customer" label="name" track-by="name" :select-label="select_label" ></multiselect>
                      </div>

                      <div v-if  = "userType === 'UTYINL' " class="form-group mb-3">
                        <label>Vendor</label><br />
                        <multiselect v-model="form.vendor" :options="LOV.vendor" label="name" track-by="name" :select-label="select_label" ></multiselect>
                      </div>

                      <div v-if  = "userType === 'UTYINL' " class="form-group mb-3">
                        <label>Port</label><br />
                          <multiselect id="mv_port" name="mv_port" :select-label="select_label" :custom-label="customLabel" v-model="form.ports" :options="LOV.ports"></multiselect>
                      </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary" @click.prevent="validationForm">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger" :to="{ name: 'appsmd-user-list'}">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>